import { h } from "preact";
import style from "./style";
import { useState } from "preact/hooks";

const Header = ({ onInfoBtnClick }) => {
  return (
    <header class={style.header}>
      <h1>Trafiknytt</h1>
      <button class={style.infoButton} onClick={onInfoBtnClick}>
        ⓘ
      </button>
    </header>
  );
};

export default Header;
