import { h } from "preact";
import style from "./style";

const InfoModalContent = ({ onClose }) => {
  return (
    <>
      <div
        style={{ float: "right", fontSize: "2rem", cursor: "pointer" }}
        onClick={onClose}
      >
        X
      </div>
      <h2 style={{ paddingTop: "9px", paddingBottom: "14px" }}>Information</h2>
      <div style={{ height: "80%" }}>
        <div>
          <p>
            Den här hemsidan visar var kontrollanter i kommunaltrafiken har
            befunnit sig den senaste tiden. Datan hämtas från olika forum på
            internet, utan att spara någon information om vem som skrivit
            foruminläggen.
          </p>
          <p>
            Hemsidan är skapad i syfte att folk ska bli mer medvetna om var
            kontrollanterna befinner sig, med förhoppningen att minska antalet
            tjuvåkningar. Vi uppmanar alla resenärer att betala för sina
            biljetter.
          </p>
        </div>

        <div style={{}}>
          <p
            style={{
              fontStyle: "italic",
              marginTop: "48px",
              marginBottom: "6px",
            }}
          >
            Om du har några frågor eller har förslag på förbättringar, hör gärna
            av dig till:
          </p>
          <a href="mailto:info@trafiknytt.se">info@trafiknytt.se</a>
        </div>
      </div>
    </>
  );
};

export default InfoModalContent;
