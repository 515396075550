import { h } from "preact";
import { Router } from "preact-router";
import { useState } from "preact/hooks";
import Modal from "react-modal/lib/components/Modal";

import Header from "./components/header";
import InfoModal from "./components/info-modal-content";

import Home from "./routes/home";

Modal.setAppElement("body");

const App = () => {
  const [modalIsOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div id="app">
      <Header onInfoBtnClick={showModal} />

      <Router>
        <Home path="/" />
      </Router>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Information"
        className="modal"
      >
        <InfoModal onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default App;
